import { Injectable } from '@angular/core';

import { LoadingController, ToastController, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class Toast {
	isOn = false;
	toast: any;
	options: any = {
		message: '',
		position: 'top',
		dismissOnPageChange: true,
		duration: 3000,
		cssClass: '',
		showCloseButton: '',
		closeButtonText: ''
	};


	constructor(public translateService: TranslateService, public toastCtrl: ToastController ) {
	}

	public show(obj): void {
		//console.log('obj', obj);
		//To make sure the previous one, if it had a close button, was dimissed
		if (this.isOn){
			this.dismiss();
			this.isOn = false;
		}


		//If the new toast has a close button
		if (obj.showCloseButton){
			this.isOn = true;
		}

		this.create(obj);
		//this.present();
	}

	public async create(obj) {

		//By default
		this.options.showCloseButton = false;
		this.options.closeButtonText = '';
		this.options.message = '';
		this.options.cssClass = 'custom-loading';
		this.options.duration = 3000;


		this.options = Object.assign(this.options, obj);

		if ('undefined' === typeof obj.message) {
			this.translateService.get('Successfully saved').subscribe(
				value => {
					this.options.message = value;
				}
			);
		} else {
			this.translateService.get(obj.message).subscribe(
				value => {
					this.options.message = value;
				}
			);
		}

		await this.toastCtrl.create(this.options).then((response) => {
			this.toast = response;
			response.present();
		});
	}

	public present(): void {
		this.toast.present();
	}

	public dismiss(): void {
		this.toast.dismiss();
	}
}

@Injectable()
export class Loading {
	loading: any;
	options: any = {
		message: '',
		dismissOnPageChange: true,
		cssClass: 'custom-loading',
		showCloseButton: '',
		closeButtonText: ''
	};
	isOn = false;

	constructor(public translateService: TranslateService, public loadingCtrl: LoadingController) {
	}

	public async show(obj) {
		await this.create(obj);
		this.isOn = true;
	}

	public async create(obj) {
		this.options = Object.assign(this.options, obj);

		if ('undefined' === typeof obj.content) {
			this.translateService.get('Waiting').subscribe(
				value => {
					this.options.content = value;
				}
			);
		} else {
			this.translateService.get(obj.content).subscribe(
				value => {
					this.options.content = value;
				}
			);
		}
		await this.loadingCtrl.create(this.options).then((response) => {
			this.loading = response;
			response.present();
		});
	}

	public dismiss() {
		this.loadingCtrl.dismiss(this.loading).then((response) => {
			//console.log('boas pessoal', response);
		}).catch((err) => {
			//console.log('Xau pessoal', err);
		});
		//this.loading.dismiss();
		this.isOn = false;
	}
	public forcedismiss() {
		this.loadingCtrl.dismiss().then(() => {
			this.isOn = false;
		});
	}
}


@Injectable()
export class Alert {
	alert: any;
	options: any = {
		title: '',
		buttons: ['OK']
	};


	constructor(public translateService: TranslateService, public alertCtrl: AlertController ) {
	}

	public show(obj): void {
		this.create(obj);
		this.present();
	}

	public create(obj): void {
		this.options = Object.assign(this.options, obj);

		if ('undefined' === typeof obj.title) {
			this.translateService.get('OK').subscribe(
				value => {
					this.options.title = value;
				}
			);
		}

		this.alert = this.alertCtrl.create(this.options);
	}

	public present(): void {
		this.alert.present();
	}
}


/*
	public showSimpleAlert(message){

		this.translateService.get(message).subscribe(
						value => {
						message = value;
						}
					)

		let alert = this.alertCtrl.create({
					title: message,
					buttons: ['OK'],

				});
		alert.present();
		return;
	}



	public showAlertWithSubTitle(title, subTitle){

		this.translateService.get(title).subscribe(
						value => {
						title = value;
						}
					)
		this.translateService.get(subTitle).subscribe(
						value => {
						subTitle = value;
						}
					)

		let alert = this.alertCtrl.create({
					title: title,
					subTitle: subTitle,
					buttons: [{
						text: 'OK'
					}]

				});
		alert.present();
		return;
	}

	public showToast(message: string, duration: number, position: string, cssClass: string) {
		let toast = this.toastCtrl.create({
		  message: message,
		  duration: duration,
		  position: position,
		  cssClass : cssClass
		});

		toast.present(toast);
	}

	public sizeOf(item) {

		let size = 0, key;
			for ( key in item) {

				if (item.hasOwnProperty(key)) size++;

			}
		return size;
	}

	public giveMeIdCheckedOf(item){

		let itemChecked = [];
		for ( var i = 0; i<this.sizeOf(item); i++){
			if (typeof item[i].checked !== 'undefined') {
				if(item[i].checked) {
					if (typeof item[i].idgroups !== 'undefined') {
						itemChecked.push(item[i].idgroups);
					}
					else if ((typeof item[i].utilizador !== 'undefined')){
						itemChecked.push(item[i].utilizador);
					}
				}
			}
		}
		return itemChecked;

	}
*/


import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController, NavParams, ModalController } from '@ionic/angular';

import { JwtHelperService } from '@auth0/angular-jwt';

import { ActionService } from '../../services/action-service';
import { Toast } from '../../controllers/message-controller';
import { ConfigService } from '../../services/config-service';
import { StringUtils } from '../../utils/string-utils';
import { VersionUtils } from '../../utils/version-utils';
import { ConsoleService } from '../../services/console/console-service';
import { WebStorageService } from '../../services/webstorage-service';
import { NgClass } from '@angular/common';
import { map, Observable } from 'rxjs';

@Component({
	selector: 'app-page-action-configfilter',
	templateUrl: 'action-configfilter.html',
	providers: [ActionService, Toast, ConfigService, JwtHelperService],
	styleUrls: ['action-configfilter.scss']
})
export class ActionConfigfilterPage implements OnInit{
	
	workflows: any;
	allworkflows: any;
	documenttypes: any;
	alldocumenttypes: any;
	auxiliaryfields: any;

	addFilterType: string = 'new'; // add new filter is selected by default
	newFiltersFromBase: any;
	hasNewFiltersFromBase: boolean = false;
	editFilter: boolean = false;

	searchWorkflowsModel: any;
	searchDocumentTypesModel: any;

	fShowWorkflows: boolean = false;
	fShowDocumentstype: boolean = false;
	fDisabledOnsubmit: boolean = false;

	finding: boolean = false;
	private filter: any;
	private access_token_decoded: any;
	private versionToCompare: string = '1.1~beta1'; // auxiliaryfields added on version 1.1~beta1
	//it necessary to check if systemdocmobile is running a version equal or greater than 1.1~beta1 to show auxiliaryfields options 

	constructor(public viewCtrl: ModalController,
		public navCtrl: NavController,
		public navParams: NavParams,
		public actionService: ActionService,
		public toast: Toast,
		public router: Router,
		private jwtHelper: JwtHelperService,
		private consoleService: ConsoleService,
	 	public localStorageService: WebStorageService
		) {

		
		}

	ngOnInit() {
		this.filter = {idactionlist: '', name: '', idswork: [], idsdocumenttype: [], idsauxiliaryfield: {}};
		let access_token = this.localStorageService.get('access_token');
		this.access_token_decoded = this.jwtHelper.decodeToken(access_token);

		this.consoleService.log('actionConfigfilterPage','ActionConfigfilterPage params');
		this.consoleService.log('actionConfigfilterPage','','',this.navParams.data);

		if (this.navParams.get('filter')) {
			this.filter = Object.assign(this.filter, this.navParams.get('filter'));
			//console.log('filter');
			this.editFilter = true;
		} else {
			this.editFilter = false;
		}

		//console.log('filter --> '+this.filter);
		
		if ('undefined' !== typeof this.filter.components) {

			for (var i = 0; i < this.filter.components.length; i++) {

				if ('idwork' == this.filter.components[i]['name']) {
					this.filter.idswork.push(this.filter.components[i]['value']);

				} else if ('iddocumenttype' == this.filter.components[i]['name']) {
					this.filter.idsdocumenttype.push(this.filter.components[i]['value']);

				} else if (this.filter.components[i]['name'].indexOf('idauxiliaryfield') > -1) {

					this.consoleService.log('actionConfigfilterPage','has index', this.filter.components[i]['name']);

					if ('object' === typeof this.filter.idsauxiliaryfield[this.filter.components[i]['name']]) {
						this.filter.idsauxiliaryfield[this.filter.components[i]['name']].push(this.filter.components[i]['value'])

					} else {
						this.filter.idsauxiliaryfield = Object.assign(this.filter.idsauxiliaryfield, {
							[this.filter.components[i]['name']]: [this.filter.components[i]['value']]}
						);
					}
				}
			}
		}

		this.consoleService.log('actionConfigfilterPage','thisfilterrr', this.filter);

		this.finding = true;

		this.actionService.findactionfiltercomponents({}).subscribe({
			next: (data: any) => {
				
				//console.log('filter --> '+this.filter);
				//console.log('versionCompare --> '+this.versionToCompare);
				//console.log('this.access_token_decoded --> '+this.access_token_decoded);
				this.finding = false;

				this.workflows = data.workflows;
				for (var i = 0; i < this.workflows.length; i++) {
					if (this.filter.idswork.indexOf(this.workflows[i]['idwork'] + '') > -1) {
						this.workflows[i]['checked'] = true;
					}
				}

				this.documenttypes = data.documenttypes;
				for ( i = 0; i < this.documenttypes.length; i++) {
					if (this.filter.idsdocumenttype.indexOf(this.documenttypes[i]['idtipodoc'] + '') > -1) {
						this.documenttypes[i]['checked'] = true;
					}
				}

				this.allworkflows = this.workflows;
				this.alldocumenttypes = this.documenttypes;


				if ('object' === typeof this.access_token_decoded) {
					if ('string' === typeof this.access_token_decoded.systemdocmobile) {
						if (VersionUtils.versionCompare(this.access_token_decoded.systemdocmobile, '>=', this.versionToCompare)) {
							this.auxiliaryfields = data.auxiliaryfields;
							for ( let data_auxiliaryfield of this.auxiliaryfields) {
								data_auxiliaryfield = Object.assign(data_auxiliaryfield, {'fShowOptions': false});
									for (let option of data_auxiliaryfield.options) {
										option = Object.assign(option, {'checked': false});
										if ('object' === typeof this.filter.idsauxiliaryfield['idauxiliaryfield' + data_auxiliaryfield.idauxiliaryfield]) {
											for (let filter_option of this.filter.idsauxiliaryfield['idauxiliaryfield' + data_auxiliaryfield.idauxiliaryfield]) {
												if (filter_option == option['idauxiliaryfield' + data_auxiliaryfield.idauxiliaryfield]) {
													option = Object.assign(option, {'checked': true});
												}
											}
										}
									}
							}

							this.consoleService.log('actionConfigfilterPage',this.access_token_decoded.systemdocmobile, 'é maior que ',  this.versionToCompare);
						} else {
							this.consoleService.log('actionConfigfilterPage',this.access_token_decoded.systemdocmobile, 'é menor que ',  this.versionToCompare);
						}
					}
				}




				this.consoleService.log('actionConfigfilterPage','auxiliaryfields', this.auxiliaryfields);


				let filtersFromBase = data.filtersFromBase;
				this.newFiltersFromBase = [];

				if ('undefined' != typeof filtersFromBase.length) {
					for (let i = 0; i < filtersFromBase.length; i++) {

						if (!filtersFromBase[i].checked) {
							this.newFiltersFromBase.push(filtersFromBase[i])
						}

					}

					if (this.newFiltersFromBase.length > 0) {
						this.hasNewFiltersFromBase = true;
						this.consoleService.log('actionConfigfilterPage','filtersFromBase', this.newFiltersFromBase)
					} else {
						this.hasNewFiltersFromBase = false;
					}
				}

				//console.log('filter --> '+this.filter);

			},
			error: err => {
				this.finding = false;
				if (401 == err.status || 403 == err.status) {
					this.router.navigate(['login']);
				}
			},
			complete: () => {

			}
		})
	}

	showHideWorkflows(): void {
		this.fShowWorkflows = !this.fShowWorkflows;
	}

	showHideDocumentstype(): void {
		this.fShowDocumentstype = !this.fShowDocumentstype;
	}

	showHideAuxiliaryfieldOptions(data): void {
		data.fShowOptions = !data.fShowOptions;
	}

	workflowChange(data): void {
		data.checked = !data.checked;
	}

	documenttypeChange(data): void {
		data.checked = !data.checked;
	}

	auxiliaryfieldOptionChange(data): void {
		data.checked = !data.checked;
	}

	newFilterFromBaseChange(filter): void {
		if ('boolean' === typeof filter.checked) {
			filter.checked = !filter.checked;
		}
	}

	getWorkflows() {

		this.workflows = this.allworkflows;
		var val = this.searchWorkflowsModel;

		// if the value is an empty string don't filter the items
		if (val && val != '' && val.trim() != '') {
			this.workflows = this.workflows.filter((item) => {
				if(typeof item.descricao !== 'undefined'){
					return (StringUtils.replaceSpecialChars(item.descricao).toLowerCase().indexOf(StringUtils.replaceSpecialChars(val).toLowerCase()) > -1);
				}

			})
		}
	}

	getDocumentTypes() {

		this.documenttypes = this.alldocumenttypes;
		let val = this.searchDocumentTypesModel;

		// if the value is an empty string don't filter the items
		if (val && val != '' && val.trim() != '') {
			this.documenttypes = this.documenttypes.filter((item) => {
				if(typeof item.descricao !== 'undefined'){
					return (StringUtils.replaceSpecialChars(item.descricao).toLowerCase().indexOf(StringUtils.replaceSpecialChars(val).toLowerCase()) > -1);
				}

			})
		}
	}

	save(): void {

		let filters: any = [];
		let filterFromBase = {name: '', idactionlist: '', idsdocumenttype: [], idsauxiliaryfield: {},  idswork: []};

		if ('new' == this.addFilterType) {

			this.filter.idswork = [];
			this.filter.idsdocumenttype = [];
			this.filter.idsauxiliaryfield = {};

			for (let workflow of this.workflows) {
				if (workflow.checked) {
					this.filter.idswork.push(workflow.idwork);
				}
			}

			for (let documenttype of this.documenttypes) {
				if (documenttype.checked) {
					this.filter.idsdocumenttype.push(documenttype.idtipodoc);
				}
			}

			if ('undefined' !== typeof this.auxiliaryfields) {
				for (let auxiliaryfield of this.auxiliaryfields) {

					let idauxiliaryfield = auxiliaryfield.idauxiliaryfield;
					this.filter.idsauxiliaryfield['idauxiliaryfield' + idauxiliaryfield] = []



					for (let option of auxiliaryfield.options) {

						if (option.checked) {

							this.filter.idsauxiliaryfield['idauxiliaryfield' + idauxiliaryfield].push(option['idauxiliaryfield' + idauxiliaryfield]);

						}
					}


				}
			}

    
			filters.push(this.filter);

			this.consoleService.log('actionConfigfilterPage','filters', '',filters);

		} else if ('fromBase' == this.addFilterType) {

			if ('undefined' != typeof this.newFiltersFromBase.length) {

				for (let i = 0; i < this.newFiltersFromBase.length ; i++) {

					if (this.newFiltersFromBase[i].checked) {
						let idsdocumenttype: any = [];
						let idswork: any = [];
						let idsauxiliaryfield: any = {};

						if ('undefined' != typeof this.newFiltersFromBase[i].components.length) {

							for (let c = 0; c < this.newFiltersFromBase[i].components.length ; c++) {

								let component = this.newFiltersFromBase[i].components[c];

								this.consoleService.log('actionConfigfilterPage',component.type);
								this.consoleService.log('actionConfigfilterPage',component.type.indexOf('auxiliaryfield'));

								if ('documenttype' == component.type) {
									idsdocumenttype.push(component.value);

								} else if ('workflow' == component.type) {
									idswork.push(component.value)

								} else if (component.type.indexOf('auxiliaryfield') > -1) {
									this.consoleService.log('actionConfigfilterPage',component);
									if('object' === typeof idsauxiliaryfield[component.name]) {
										idsauxiliaryfield[component.name].push(component.value)
										this.consoleService.log('actionConfigfilterPage','idsauxiliaryfield push');
									} else {
										this.consoleService.log('actionConfigfilterPage','idsauxiliaryfield[] and push');
										idsauxiliaryfield[component.name] = [];
										idsauxiliaryfield[component.name].push(component.value);
									}

								}
							}

							filterFromBase = {
								name: this.newFiltersFromBase[i].name,
								idactionlist: '',
								idsdocumenttype: idsdocumenttype,
								idswork: idswork,
								idsauxiliaryfield: idsauxiliaryfield
							}

							filters.push(filterFromBase);
							this.consoleService.log('actionConfigfilterPage','filters', '',filters);

						} else {
							this.consoleService.log('actionConfigfilterPage','newFiltersFromBase.components.length undefined')
						}

					}


				}

			} else {
				this.consoleService.log('actionConfigfilterPage','newFiltersFromBase.length undefined')
			}

		} else {
			this.consoleService.log('actionConfigfilterPage','add filter is not from type new or base')
		}

		this.consoleService.log('actionConfigfilterPage','filters', '',filters);
		
		this.fDisabledOnsubmit = true;
		this.actionService.saveactionfilter({filters}).subscribe({
			next: (data: any) => {
				//console.log('teste');
				this.fDisabledOnsubmit = false;
				this.filter = data;
				//this.toast.show({});
				
				this.dismiss();
				this.viewCtrl.dismiss();
			},
			error: err => {
				//console.log('teste2');
				if (422 == err.status) {
					this.fDisabledOnsubmit = false;
					for (let e of err) {
						this.filter['error_' + e.field] = e.message;

						if ('idswork' == e.field) {
							this.fShowWorkflows = true;
						}

						if ('idsdocumenttype' == e.field) {
							this.fShowDocumentstype = true;
						}
					}
				}
			},
			complete: () => {
				//console.log('teste3');
			}
		});
	}

	disabledOnsubmit(): boolean {
		return this.fDisabledOnsubmit;
	}

	btnDisabled() {

		if ('new' == this.addFilterType) {
			if ('' === this.filter.name) {
				return true;
			}
			return false;

		} else if ('fromBase' == this.addFilterType) {

			let disable = true;
			for (let i = 0; i < this.newFiltersFromBase.length; i++) {
				if (this.newFiltersFromBase[i].checked) {
					disable = false;
					break;
				}
			}

			return disable;
		}


	}

	dismiss(){
		//console.log('dismiss')
		this.viewCtrl.dismiss({type:'Cancel'});
	}
}

import { Component, OnInit } from '@angular/core';
import { NavController, NavParams, IonNav } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { Events } from '../../services/event-service';
import { DocumentService } from '../../services/document-service';
import { ActionService } from '../../services/action-service';
import { UserService } from '../../services/user-service';
import { FileService } from '../../services/file/file-service';
import { StorageUtils } from '../../utils/storage-utils';
import { ActionSheet } from '../../controllers/actionsheet-controller';
import { timer } from 'rxjs';


@Component({
	selector: 'app-page-chronologicalscheme',
	templateUrl: 'chronologicalscheme.html',
	providers: [DocumentService, ActionService, UserService],
	styleUrls: ['chronologicalscheme.scss']
})
export class ChronologicalschemePage {

	document: any;
	//actions: Array<{}>;

	revisions: Array<{ titulo: string; data_int: string; actions: any }> = [];

	chronschemeTypes: Array<{name: string; description: string; selected: boolean}> = [];

	ctype: string;
	is_complete_chron_scene = false;

	fSortasc: boolean;

	finding = false;

	constructor(
				public navCtrl: NavController,
				public nav: IonNav,
				public navParams: NavParams,
				public events: Events,
				public userService: UserService,
				public documentService: DocumentService,
				public actionService: ActionService,
				public translateService: TranslateService,
				public fileService: FileService,
				public actionSheet: ActionSheet) {

				//console.log('constructor');
		const usr = userService.getUser();

		if (this.navParams.get("params")) {

			let params = this.navParams.get("params");

			if (typeof params.document === 'object' && typeof params.document.iddoc === 'number') {

				this.document = params.document;
			}
			if (typeof params.type != undefined) {
				this.ctype = params.type;
			} else {
				if (undefined != typeof usr.defaultchronschema && '' != usr.defaultchronschema) {
					this.ctype = usr.defaultchronschema;
				} else {
					this.ctype = 'esquema_cronologico_info';
				}
			}
		}

		let translation = "";
		this.translateService.get('Detailed').subscribe(
			value => {
				translation = value;
			}
		);
		this.chronschemeTypes[this.chronschemeTypes.length] = {name:'esquema_cronologico_info', description: translation, selected: true};

		this.translateService.get('Brief').subscribe(
			value => {
				translation = value;
			}
		);
		this.chronschemeTypes[this.chronschemeTypes.length] = {name: 'short_chron_scene', description: translation, selected: false};

		this.translateService.get('Forwards').subscribe(
			value => {
				translation = value;
			}
		);
		this.chronschemeTypes[this.chronschemeTypes.length] = {name: 'esquema_forward', description: translation, selected: false};

		if ('undefined' != typeof usr.defaultchronschemasort && '' != usr.defaultchronschemasort) {
		} else {
			usr.defaultchronschema = "desc";
		}
		this.fSortasc = "asc" === usr.defaultchronschemasort ? true : false;

		this.chronologicalscheme({
			id: this.document.iddoc,
			type: this.ctype,
			sort: usr.defaultchronschemasort
		});
	}

	ionViewWillLeave() {
	}



	public chronologicalscheme(params: any): void {

		this.finding = true;

		this.actionService.chronologicalscheme(params).subscribe({
			next: (data: any) => {
				this.finding = false;
				let expanded = true;
				let usr = this.userService.getUser();
				StorageUtils.getItem("defaultchronschemaexpanded");

				if ('undefined' != typeof usr.defaultchronschemaexpanded && '' != usr.defaultchronschemaexpanded) {
					if ("0" == usr.defaultchronschemaexpanded) {
						expanded = false;
					}
				}

				if (data.chrontypes) {

					for (let datachrontype of data.chrontypes) {

						let chrontype_is_new = true;  //check if chronschemeTypes received already exists on this.chronschemeTypes


						for (let chronschemeType of this.chronschemeTypes) {
							if (chronschemeType.name == datachrontype.name) {
								chrontype_is_new = false;
							}
						}

						if (chrontype_is_new) {
							this.chronschemeTypes[this.chronschemeTypes.length] = {name: datachrontype.name, description: datachrontype.description, selected: false};
						}
					}

				}

				// -1 Ler Doc Encaminhado
				// -7 Encaminhar Doc
				// -8 Encaminhar Doc c/ Accao
				// -9 Encaminhar Doc p/ exterior

				let idaccaoWithValidComments = [-1, -7, -8, -9, -17];
				let idaccaoWithValidResults = [-7, -8, -9];

				this.revisions = [];

				if (data.list || data.revlist) {

					let revlist = [];

					//before version beta chronschme come in list. since version beta and because of chronschema total it was changed for revlist
					if (data.list && !data.revlist) {
						//console.log("listtttttttttttt");
						let list = data.list;
						if ('undefined' != typeof list[0].idrev) {
							let idrev = list[0].idrev;
							revlist[idrev] = {};
							revlist[idrev] = Object.assign(revlist[idrev], {actions: data.list});
						}

					} else if (data.revlist) {
					//console.log("revvvvvvvvvvlist");
						revlist = data.revlist;
					}

					if ('undefined' != typeof revlist) {
					for (let idrev in revlist) {

						let actions = revlist[idrev].actions;

						for (let action of actions) {

							action = Object.assign(action, {hasResultValid: false});
							if ( action.resultadotexto && ((action.idmultiplos) || (action.realizado && (idaccaoWithValidResults.indexOf(action.idaccao) > -1)))) {
								action = Object.assign(action, {hasResultValid: true});
							}

							if (action.comentario && action.idaccao > 0) {
								action = Object.assign(action, {hasCommentValid: true, comentarioicone: 'far fa-comment'});
							}


							if (action.comentario && action.idaccao < 0) {

								action = Object.assign(action, {hasCommentValid: false});
								action = Object.assign(action, {comentarioicone: 'fas fa-info'});
								// exemplo : comentario = Documento ligado às diretorias ...

								if (idaccaoWithValidComments.indexOf(action.idaccao) > -1) {
									action = Object.assign(action, {hasCommentValid: true});
									action = Object.assign(action, {comentarioicone: 'far fa-comment'});
								}

							}

							action = Object.assign(action, {expanded: expanded});

						}

						this.revisions.push({titulo: revlist[idrev].titulo, data_int: revlist[idrev].data_int,  actions: actions});
					}
					}



				}
				//console.log(this.revisions);
			},
			error: () => {
				this.finding = false;
			}
		});
	}

	toggleExpand(action: any): void {

		if (action.expanded) {
			action.expanded = false;
		} else {
			action.expanded = true;
		}

	}

	goToInfo(): void {
		this.nav.getActive().then((view) => {
			this.events.publish('doc:pushPage', {
				page: 'InfoPage',
				params: {
					document: this.document,
					frompage: 'ChronologicalschemePage'
				}
			});
			timer(50).forEach(() => {
				this.nav.removeIndex(view.nav.views.length - 2);
			});
		});
	}

	goToMark(): void {
		this.nav.getActive().then((view) => {
			this.events.publish('doc:pushPage', {
				page: 'MarkPage',
				params: {
					document: this.document,
					frompage: 'ChronologicalschemePage'
				}
			});
			timer(50).forEach(() => {
				this.nav.removeIndex(view.nav.views.length - 2);
			});
		});
	}

	onChronologicalChange(ctype: any): void {

		if ('complete_chron_scene' == ctype) {
			this.is_complete_chron_scene = true;
		} else {
			this.is_complete_chron_scene = false;
		}

		let sort = '';
		if (this.fSortasc) {
			sort = 'asc';
		} else {
			sort = 'desc';
		}

		let params = {id: this.document.iddoc, type: ctype, sort: sort};
		this.chronologicalscheme(params);


	}

	sort(): void {
		this.fSortasc = !this.fSortasc;

		for (let revision of this.revisions) {
			let actions = [];
			for (let i = revision.actions.length; i > 0; i--) {
				actions.push(revision.actions[i - 1]);
			}
			revision.actions = actions;
		}
    }

	download(): any {

		let chronschemasort;
		if (this.fSortasc) {
			chronschemasort = "asc";
		} else {
			chronschemasort = "desc";
		}

		if ("0" == StorageUtils.getItem("platform_cordova")) {
			this.documentService.downloadbytoken({iddoc: this.document.iddoc, chronschemasort: chronschemasort}, '');
		} else {
			this.fileService.download(this.document);
		}
	}

	openActionSheetFor(){
		this.actionSheet.show('ChronologicalschemePage', 'more', {item: this.document});
	}
}
